@use 'sass:math';

$vw: 1vw;
$px: 1px;

@function make-unitless($value){
    @if (type-of($value) != number) {@return $value;}

    @if (unit($value) == px) {@return math.div($value, 1px);}
    @if (unit($value) == vw) {@return math.div($value, 1vw);}
    @if (unit($value) == vh) {@return math.div($value, 1vh);}
    @if (unit($value) == em) {@return math.div($value, 1em);}
    @if (unit($value) == "%") {@return math.div($value, 1%);}

    @return $value;
}
@function px-to-vw($px, $ratio){
    @return math.div($px*100, $ratio) * $vw;
}
@function vw-to-px($vw, $ratio){
    @return math.div($vw*$ratio, 100) * $px;
}
@function ratio-from-px($_px, $ratio){
    @return min(make-unitless($_px)*$px, px-to-vw(make-unitless($_px), $ratio));
}
@function ratio-from-vw($_vw, $ratio){
    @return min(make-unitless($_vw)*$vw, vw-to-px(make-unitless($_vw), $ratio));
}


$test: #80ba3e;
$theme-ui-colors-background: #F5F4E9;
$theme-ui-colors-canvas: #F5F4E9;
$theme-ui-colors-muted: #F2F2F2;
$theme-ui-colors-offer: #FFDD00;
$theme-ui-colors-navbar: #383838;
$theme-ui-colors-card: #FFFFFF;
$theme-ui-colors-tertiary: #33582F;
$theme-ui-colors-accent: #7B4A78;
$theme-ui-colors-tooltip: #383838;
$theme-ui-colors-border: #CCCCCC;
$theme-ui-colors-separator: #E4E4E4;
$theme-ui-colors-text: #000000;
$theme-ui-colors-textOnDark: #FFFFFF;
$theme-ui-colors-tertiaryText: #33582F;
$theme-ui-colors-tertiaryTextOnDark: #63b15b;
$theme-ui-colors-accentText: #904A9C;
$theme-ui-colors-accentTextOnDark: #E380F3;
$theme-ui-colors-whisper: #666666;
$theme-ui-colors-primary: #4F850D;
$theme-ui-colors-primaryOnDark: #9CDE4E;
$theme-ui-colors-secondary: #0077D9;
$theme-ui-colors-danger: #D64040;
$theme-ui-colors-like: #D64040;
$theme-ui-colors-neutral: #666666;
$theme-ui-colors-rating: #CFA200;
$theme-ui-colors-focused: #63b3ed;
$theme-ui-colors-disabled: #CCCCCC;
$theme-ui-colors-info: #0077D9;
$theme-ui-colors-error: #B01E1E;
$theme-ui-colors-success: #33582F;
$theme-ui-colors-warning: #DEAE00;
$theme-ui-colors-easy: #4379B2;
$theme-ui-colors-moderate: #B05963;
$theme-ui-colors-difficult: #383838;
$theme-ui-colors-tour: #383838;
$theme-ui-colors-highlight: #E55252;
$theme-ui-colors-pioneer: #B89B4F;
$theme-ui-colors-expert: #788A8F;
$theme-ui-colors-facebook: #1877f2;
$theme-ui-colors-apple: #000000;

$page-max-width: 800;
$page-width: 100;
$page-ratio: math.div($page-max-width,  math.div($page-width, 100));

@function ratio-from($_px){
    @if (unit($_px) == px) {@return ratio-from-px($_px, $page-ratio);}
    @if (unit($_px) == vw) {@return ratio-from-vw($_px, $page-ratio);}
    // exeption
    @return $error "unit not supported";
}


body, html {
    margin: 0;
    padding: 0;

    font-family: "Segoe UI", "Helvetica Neue", sans-serif;
    font-weight: 200;

    background-color: $theme-ui-colors-background;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100vw;

    overflow-x: clip;
}
* {
    color: $theme-ui-colors-text;
    font-size: ratio-from(20px);
}
a{
    text-decoration: none;
}

//////////////////////////////////////////////////////////
/// HEADER
//////////////////////////////////////////////////////////

header {
    box-sizing: border-box;
    width: 100%;

    background-color: $theme-ui-colors-navbar;
    padding: ratio-from(15px);

    display: flex;
    justify-content: flex-start;
    align-items: center;


}

header>* {
    color: $theme-ui-colors-textOnDark;
    text-decoration: none;
    font-size: ratio-from(15px);
    font-weight: bold;

    padding: 0 ratio-from(10px);
    opacity: 0.8;

    // line-height: ratio-from(1px);
}



#logo-stats{
    position: absolute;
    top: ratio-from(25px);
    left: ratio-from(80px);
    color: $theme-ui-colors-textOnDark;
    font-style: italic;
    font-size: ratio-from(13px);
    opacity: 0.9;
}

.header-logo {
    position: relative;
    opacity: 1;
    width: ratio-from(110px);
}
.header-logo>img {
    width: 100%;
    height: auto;
}

header>a:hover {
    opacity: 1;
}

header>a.selected {
    opacity: 1;
    color: $theme-ui-colors-primaryOnDark;
    border: none;
}
header>.spacer {
    flex-grow: 1;
}


footer{
    color: $theme-ui-colors-neutral;

    text-align: right;
    padding: ratio-from(30px) 0;
    font-size: ratio-from(15px);
    width: 100%;
    padding-right: ratio-from(30px);
    box-sizing: border-box;
}
footer>*{
    color: $theme-ui-colors-neutral;
    font-size: ratio-from(15px);
}

//////////////////////////////////////////////////////////
/// PAGE
//////////////////////////////////////////////////////////


.page, .pre-page {
    position: relative;

    background-color: $theme-ui-colors-card;

    width: 100%;
    max-width: 800px;

    margin-top: ratio-from(50px);
    
    box-sizing: border-box;
    padding: ratio-from(30px);

    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;

    display: flex;
    flex-direction: column;
    gap: ratio-from(15px);
}


.pre-page{
    padding: 0;
}
.pre-page>div{
    padding: 0 ratio-from(30px) ratio-from(30px) ratio-from(30px);
}

.page.page-tours{
    padding-bottom: ratio-from(10px);
}


.page#sticky{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: ratio-from(10px) ratio-from(30px);
}


#sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;

    box-sizing: border-box;
}

#sticky>h1{
    width: 15%;
}
#sticky>div{
    width: 85%;
}




//////////////////////////////////////////////////////////
/// TITLES AND UTILS
//////////////////////////////////////////////////////////

h1{
    margin: 0;
    font-size: ratio-from(35px);
}
.h1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ratio-from(20px);
}
.h1>.icon{
    width: ratio-from(50px);
    height: ratio-from(50px);
    font-size: ratio-from(30px);
    background-color: $theme-ui-colors-navbar;
    color: $theme-ui-colors-textOnDark;
    padding: ratio-from(10px);
    border-radius: 100%;
    box-sizing: border-box;
}
.h1.tour-title{
    justify-content: flex-start;
}

h2{
    color: $theme-ui-colors-neutral;
    margin: 0;
    margin-top: ratio-from(20px);
    font-size: ratio-from(22px);
    text-transform: uppercase;
}
h2#tour-date{
    font-size: ratio-from(18px);
}

h3{
    margin: 0;
}

.profile{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ratio-from(20px);
}
.profile>img{
    width: ratio-from(100px);
    height: ratio-from(100px);
    border-radius: 100%;
}
.profile>img.small{
    width: ratio-from(50px);
    height: ratio-from(50px);
}

.line {
    height: ratio-from(3px);
    background-color: $theme-ui-colors-border;
}


.small-line {
    width: 100%;
    height: ratio-from(2px);
    background-color: $theme-ui-colors-border;
}


.clickable{
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


//////////////////////////////////////////////////////////
/// INPUTS
//////////////////////////////////////////////////////////

.form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form>span *{
    width: 33%;
    font-weight: bold;
    color : rgba(102,102,102, 1);
}
.form .icon{
    font-size: ratio-from(20px);
}
.form>.input{
    position: relative;
    width: 67%;
}
.input-icon{
    position: absolute;
    left: ratio-from(10px);
    top: 50%;
    transform: translateY(-50%);
    color: rgba(102,102,102, 1);
    z-index: 10;
    font-size: ratio-from(25px);
}
input{
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    // background-color: rgba(0,0,0,0.10);
    border-color: $theme-ui-colors-text;
    border-radius: ratio-from(8px);
    border-style: solid;
    border-width: ratio-from(2px);
    color: var(--theme-ui-colors-text);
    display: block;
    min-height: 2.5rem;
    width: 100%;
    font-size: ratio-from(16px);
    font-family: inherit;
    line-height: inherit;
    text-overflow: ellipsis;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    transition: none;
    background: rgba(0,0,0,0.10);
    animation: none;
}
input:focus{
    outline: none;
    border-color: $theme-ui-colors-focused !important;
}
input.info{
    border-color: $theme-ui-colors-info;
}
input.error{
    border-color: $theme-ui-colors-moderate;
}
input.valid{
    border-color: #68bd00;
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}
input.loading{
    // border-color: $theme-ui-colors-primary;
    --border-angle: 0turn; // For animation.
    border-color: transparent;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #0077D9, #0077D9, 99%, transparent);
    --main-bg: conic-gradient(
        from var(--border-angle),
        rgb(255, 255, 255),
        rgb(255, 255, 255) 5%,
        rgb(255, 255, 255) 60%,
        rgb(255, 255, 255) 95%
      );
    background: 
        var(--main-bg) padding-box,
        var(--gradient-border) border-box,
        var(--main-bg) border-box;

    background-position: center center;

    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
        to {
        --border-angle: 1turn;
        }
    }
}



.select, .multi-select{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.select>a,  .multi-select>a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: ratio-from(5px);
    border-width: ratio-from(2px);
    border-style: solid;
    border-color: $theme-ui-colors-border;
    margin: 0;
    font-weight: bold;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.select > a:nth-last-child(n+2){
    border-right: none;
  }
  

.icon {
    font-family: kmt-font;
}

.multi-select>a.icon{
    border-radius: 100%;
    font-size: ratio-from(30px);
    margin: 0 ratio-from(10px);
    font-weight: lighter;
    padding: ratio-from(5px);
}
.selected{
    background-color: $theme-ui-colors-navbar;
    color: $theme-ui-colors-textOnDark;
    border-width: ratio-from(2px);
    border-style: solid;
    border-color: $theme-ui-colors-primary;
}
.icon.selected{
    color: $theme-ui-colors-secondary;
    background-color: #EEEEEE;
}





.back-btn{
    position: absolute;
    top: ratio-from(10px);
    left: ratio-from(10px);
    padding: ratio-from(10px);
    border-radius: ratio-from(3px);
    font-size: ratio-from(24px) !important;

    background-color: $theme-ui-colors-navbar;
    color: $theme-ui-colors-textOnDark;
}


//////////////////////////////////////////////////////////
/// CHARTS
//////////////////////////////////////////////////////////


.chart-container{
    position: relative;
}
.chart-container>span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ratio-from(20px);
    color: $theme-ui-colors-text;
    font-weight: bold;
    z-index: 10;
    width: 85.5vw;
    max-width: ratio-from(700px);
    text-align: center;
}


//////////////////////////////////////////////////////////
/// TOURS
//////////////////////////////////////////////////////////



#tour-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#tour-list>*{
    margin-bottom: ratio-from(20px)
}
// last-last-child
#tour-list>*:nth-last-child(-n+2){
    margin-bottom: 0;
}

.expand{
    text-align: center;
    font-size: ratio-from(40px) !important;
    cursor: pointer;
}

.tour{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ratio-from(20px);
}
.tour>img{
    width: ratio-from(100px);
    height: ratio-from(100px);
}
.tour>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ratio-from(550px);
    height: ratio-from(100px);
}
.tour>span{
    width: ratio-from(40px);
    text-align: right;
    font-size: ratio-from(24px) !important;
}

.tour>div>div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ratio-from(10px);
}



//////////////////////////////////////////////////////////
/// LIST OF STATS
//////////////////////////////////////////////////////////


.stats{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.stats.vertical{
    flex-direction: column;
    align-items: flex-start;
    gap: ratio-from(10px);
}

.stats .value{
    font-weight: bold;
}
.stats .icon{
    font-size: ratio-from(18px);
    color: rgba(102,102,102, 1);
}

table.stats td:nth-child(n+2) {
    padding-left: ratio-from(20px);
}

//////////////////////////////////////////////////////////
/// TO_REFACTOR
/// TODO: refacto
//////////////////////////////////////////////////////////

#komoot-id-help{
    display: none;
    position: absolute;
    background-color: $theme-ui-colors-navbar;
    padding: ratio-from(15px);
    color: $theme-ui-colors-textOnDark;
    border-radius: ratio-from(5px);

    z-index: 100;

    width: ratio-from(700px);
}
#map-container{
    position: relative;

    width: 100%;
    height: ratio-from(500px);

    overflow-x: hidden;
    overflow-y: hidden;
}

#map-container.fullscreen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
#map-container>.btn-container{
    position: absolute;
    top: ratio-from(10px);
    left: ratio-from(800px-70px);
    transform: translateX(-100%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ratio-from(10px);

    z-index: 200;
}

#map-container.fullscreen>.btn-container{
    
    left: calc(100vw - ratio-from(20px));
    transform: translateX(-100%);
}

#map-container>.btn-container>a{
    background-color: $theme-ui-colors-textOnDark;
    padding: ratio-from(5px);
    border-radius: ratio-from(5px);
    font-size: ratio-from(25px);
}
$a: 1;
$map_width: 740*$a;
$resolution: math.div((500*$a),$map_width);
$map_height: $map_width*$resolution;
$actual_width: 740;
$scale: math.div($actual_width,$map_width);
$translate: -(math.div(1, $scale)-1)*50%;

@debug $scale;
@debug $translate;

#map{
    width: 740px;
    height: 500px;
    // transform: scale($scale) translate($translate, $translate);
}

#map-loading{
    position: absolute;
    top: ratio-from(20px);
    left: ratio-from(20px);
    font-size: ratio-from(20px);
    color: $theme-ui-colors-text;
    font-weight: bold;
    z-index: 10;
    background-color: $theme-ui-colors-textOnDark;
    padding: ratio-from(5px);
    border-radius: ratio-from(5px);
}



/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.progress-bar{
    width: ratio-from(200px);
    height: ratio-from(20px);
    background-color: $theme-ui-colors-disabled;
}

.progress-bar>div{
    height: 100%;
    background-color: $theme-ui-colors-primary;
    width: 0;
}